import gql from 'graphql-tag';

export const administrator = gql`
  query organizationUser($id: String!) {
    organizationUser(id: $id) {
      id
      apiKey
      authUid
      email
      firstName
      lastName
      phone
      role
    }
  }
`;

const administrators = gql`
  query administrators(
    $filter: JSONObject
    $hitsPerPage: Int
    $order: [[String]]
    $organizationId: String
    $page: Int
  ) {
    organizationUsers(
      filter: $filter
      hitsPerPage: $hitsPerPage
      order: $order
      organizationId: $organizationId
      page: $page
    ) {
      nbHits
      nbPages
      hitsPerPage
      page
      hits {
        id
        createdAt
        email
        firstName
        lastName
      }
    }
  }
`;

export default administrators;
