import { Form, SubmissionError, submit, useDispatch } from 'dataformjs';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Button from '../../../../components/base/Button';
import CircularProgress from '../../../../components/base/CircularProgress';
import GridSplit from '../../../../components/base/GridSplit';
import administratorData from '../../../../objects/administrators/datas.json';
import {
  administratorsCreateAPIKey as createAPIKeyMutation,
  administratorsUpdate as updateMutation,
} from '../../../../objects/administrators/mutations';
import { administrator as administratorQuery } from '../../../../objects/administrators/queries';
import OrganizationUserType from '../../../../types/OrganizationUser';
import requiredAuth from '../../../../utils/requireAuth';

const AdministratorPage: FC<PageProps> = ({ params: { id } }) => {
  const formName = 'place';
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [updateMutationFunc] = useMutation(updateMutation);
  const [createAPIKeyMutationFunc] = useMutation(createAPIKeyMutation);
  const { data, loading, refetch } = useQuery(administratorQuery, {
    variables: {
      id,
    },
  });

  const administrator: OrganizationUserType =
    data && (data.organizationUser as OrganizationUserType);

  if (!administrator) {
    return <CircularProgress fullPage />;
  }

  const handleCreateApiKeyOnClick = () => {
    toast.promise(
      createAPIKeyMutationFunc({
        variables: {
          id,
        },
      }),
      {},
    );
  };

  const handleSaveOnClick = () => {
    dispatch(submit(formName));
  };

  const handleOnSubmit = async (values: any) => {
    let result;
    try {
      result = await updateMutationFunc({
        variables: {
          id,
          data: {
            ...values,
          },
        },
      });
    } catch (error) {
      throw new SubmissionError({
        _error: error instanceof Error ? error.message : error,
      });
    }

    if (result.errors) {
      const error = result?.errors?.map(({ message }) => message).join(',');
      throw new SubmissionError({
        _error: error,
      });
    }

    refetch();
  };

  return (
    <GridSplit
      header={{
        actions: (
          <div className="flex space-x-3">
            <Button
              onClick={handleCreateApiKeyOnClick}
              size="small"
              status="secondary"
            >
              Créer une clé API
            </Button>
            <Button onClick={handleSaveOnClick} size="small">
              Enregistrer
            </Button>
          </div>
        ),
        back: {
          onClick: () => {
            window.history.back();
          },
          text: t(`back`),
        },
        title: administrator.firstName,
      }}
    >
      {loading && (
        <div>L&apos;administrateur est en cours de rafraichissement</div>
      )}
      <Form
        className="mx-6 mb-6"
        datas={administratorData}
        hideSubmitButton
        initialValues={administrator}
        name={formName}
        onSubmit={handleOnSubmit}
        submitProps={{
          label: 'Enregistrer',
          size: 'small',
        }}
      />
    </GridSplit>
  );
};

export default requiredAuth(AdministratorPage, {
  admin: true,
  layout: true,
});
